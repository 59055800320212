import styled from "styled-components";
import {Link} from "gatsby";

const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: ${ props => props.long ? 'column' : 'row' };
    flex-wrap: wrap;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    width: -webkit-fill-available;
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 80%;
    max-width: 1250px;
    @media (max-width: 1160px) {
        width: 100%;
      }
    @media (max-width: 769px) {
      flex-direction: column;
      justify-content: center;
      margin: 10px;
    }
`;

const Bar = styled.h1`
    text-align: center;
    background-color: #202620;
    color: #fff;
    padding: 25px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  text-align: left;
  margin: 10px;
  margin-left: 0px;
  width: -webkit-fill-available;
  align-items: center;
  margin: 0;
`;

const Title = styled.h1`
  font-size: 30px;
  font-weight: bold;
  margin: 20px;
  margin-left: 0px;
  text-align: left;
  width: 90%;
  @media (max-width: 769px) {
    font-size: 20px;
  }
`;

const LargeText = styled.span`
  font-size: 30px;
  font-weight: bold;
  margin: 20px;
  margin-left: 0px;
  text-align: left;

  @media (max-width: 769px) {
    font-size: 20px;
  }
`;

const SubTitle = styled.h2`
  font-size: 20px;
  font-weight: 700;
  margin: 10px;
  margin-left: 0px;
  width: 90%;
  @media (max-width: 769px) {
    font-size: 16px;
  }
`;

const Para = styled.div`
  font-size: 16px;
  margin: 10px;
  margin-left: 0px;
  width: 95%;
`;

const LinkBtn = styled(Link)`
  color: rgb(30, 115, 190);
  :hover {
    color: #000;
    cursor: pointer;
`;

const Item = styled.li`
    padding-left: 20px;
    padding-top: 10px;
    font-size: 16px;
`;

const DisplayImg = styled.img`
    width: 95%;
`;

const AddBtn = styled.h3`
    color: #000;
    background-color: #E2AE3B;
    border-top: 8px solid #c99930;
    width: 300px;
    height: 70px;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 8px;
    h3:-webkit-any-link {
        color: #fff;
        text-decoration: none;
      }
`;

const Header = styled.div`
    display: flex;
    width: 95%;
    justify-content: space-between;
    background-color: #E2AE3B;
    padding: 20px;
`;

export {
    Container,
    Bar,
    Info,
    Title,
    SubTitle,
    Para,
    LargeText,
    LinkBtn,
    Item,
    Wrapper,
    DisplayImg,
    AddBtn,
    Header,
}