import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Wrapper} from "../styles/productStyles"
import Sidemenu from "../components/sideMenu"
import {Container, Title, Info, SubTitle, Para, Header, LargeText} from "../styles/productStyles"
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { formatPrice, getImage } from "../helpers";

export default ({ data: { nodeEquipment } }) => {
    const { title, field_price, body: { processed: info }, relationships: {field_images: picture}  } = nodeEquipment;
    return(
        <Layout>
            <SEO 
            title={title} 
            description={info}
            />
            <Container>
            <Wrapper>
                <Container>
                    <Info>
                        <Title>{title}</Title>
                        <div style={{width: "95%"}}>
                          <Carousel>
                            {picture ? picture.map((item, index) => {
                              let image = getImage(nodeEquipment, index);

                              return (
                                <div style={{display: "flex", height: "100%"}} key={index}> 
                                  <img style={{objectFit: "contain"}} alt={image.alt} title={image.title} src={image.url} />
                                </div>
                              );
                            }) : null}
                            
                          </Carousel>
                          </div>
                        <Header>
                            <LargeText>${formatPrice(field_price, 0)}</LargeText>
                            <LargeText>CALL TODAY: {process.env.GATSBY_PHONE_NUMBER}</LargeText>
                        </Header>
                        <LargeText style={{paddingTop: "20px"}}>SHORT DESCRIPTION</LargeText>
                        <Para dangerouslySetInnerHTML={{ __html: info }}></Para>
                        <LargeText style={{paddingTop: "20px"}}>LONG DESCRIPTION</LargeText>
                        <SubTitle>Advantages</SubTitle>
                        <div style={{height: "500px", overflow: "scroll", margin: "25px", marginTop: "0"}} dangerouslySetInnerHTML={{ __html: nodeEquipment.field_long_description.processed }}></div>
                    </Info>
                </Container>
                <Sidemenu/>
            </Wrapper>

            </Container>
            
        </Layout>
    );
};

export const query = graphql`
  query EquipmentQuery($slug: String!) {
    nodeEquipment(fields: { slug: { eq: $slug } }) {
    title
    field_price
    body {
        processed
    }
    field_long_description {
        processed
      }
    relationships {
      field_category {
        name
        relationships {
          field_image {
          relationships {
              field_media_image {
                  url
              }
            }
          }
        }
      }
      field_images {
        name
        field_media_image {
          title
          alt
        }
        relationships {
          field_media_image {
              url
          }
        }
      }
    }
  }
}
`
